import React from "react";

// CSS
import "./Write.css";

let Write = () => {
  return (
    <div id="Write">
      <svg
        id="svg"
        data-name="레이어 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 566.93 141.73"
      >
        <path
          id="mmd"
          className="cls-1"
          d="M45.32,70.67a137.27,137.27,0,0,0-33.85,47.9c-1.5,3.55-.72,8.75,4.52,7.17,4.53-1.37,8.37-7.41,11.34-10.83q6.14-7.06,12.72-13.71,9.3-9.39,19.44-17.91c4-3.34,7.85-7.46,12.77-9.43,8-3.23,9.16,3.58,4.58,9.19-8.21,10.07-18,18.31-25,29.4-2,3.22-2.38,8.5,2.22,9.61C60.85,123.7,69.4,110.35,73,106c7-8.49,14.38-16.85,22.64-24.18,4-3.58,8.8-8,14.11-9.46,8-2.26,7.47,4.61,3.82,10-7.53,11.08-18,19.83-26.53,30.08-2.21,2.64-5.33,6.73-5,10.41.61,6.27,8,3.61,11.69,1.71,9.46-4.88,17.6-12.35,25.6-19.27Q133,93.5,145.64,80.7c2.82-2.85,7.7-9.17,7-9.65S149,75.44,147.8,77a313.63,313.63,0,0,0-17.92,26.55c-4.49,7.34-10.23,14.17-12.33,22.7a3.75,3.75,0,0,0,0,2.58c1.68,3.38,7.61-5,8.55-6.22,12.63-16.93,30-31.46,46.65-44.31,2.37-1.83,7-4.21,10.05-4,5.17.36,1.39,4.33-.63,6.93-8.06,10.35-18.54,18.88-24.82,30.58-1.84,3.45-4.09,13.89,3.39,11.9a11,11,0,0,0,4.7-3.26c14.82-15,26.91-33.17,45.36-44.19,2.46-1.47,5.24-2.91,8.17-2.94a2.11,2.11,0,0,1,1.69.54c.57.67.2,1.7-.25,2.47-1.79,3.05-4.46,5.45-7,7.91A119.64,119.64,0,0,0,199.27,101c-4.24,6-12.9,15.82-9.12,23.82,4.24,9,22.37-8.06,26.77-11.61a173,173,0,0,0,25.36-25.18"
        ></path>
        <path
          id="core"
          className="cls-1"
          d="M242.28,88.06c11-9.12,30.84-26.31,48.09-17.39-9.56-4.94-20.89,1-29.29,5.63a99.22,99.22,0,0,0-23,17.76c-4.9,5-10.21,9.89-13.85,16-3.21,5.39-5.47,16.92,4.14,17.41a12.93,12.93,0,0,0,6.26-1.7c11.45-5.68,21.34-15.93,30.46-24.71,12-11.59,23.4-23.87,34.41-36.42C309,53.78,318.34,42.7,327.15,31.26c2.86-3.72,10.19-10.7,10.41-15.71,0,0-.05-.05-.06-.05-1.87-2.75-50.36,67-69.24,96.89-1.89,3-3.31,6.8-2.75,10.4a5.61,5.61,0,0,0,3.37,4.53,6.9,6.9,0,0,0,3.41.07c9.33-1.69,17.73-11.86,24.72-17.75,12.17-10.25,24.18-20.69,36.28-31,3.68-3.14,7.75-6.29,12.38-7.75S359,68.32,362,72.13s-1.78,12.45-6.5,13.55c4.9,1.92,12.83-13.24,2.51-15-9.86-1.65-15.62,1.87-23.65,7-9.12,5.79-18,12.66-24.31,21.55-4.86,6.88-13.37,23.65-1.18,28,3.58,1.27,7.56.71,11.17-.45,21.71-7,36.57-26.61,53.88-40.38,14-11.13,31.32-20,49.05-17.8-23.9-3-45.12,14.27-57.94,32.5-4.25,6-11.31,19.84-5,26.66s21.28-.59,27.57-4.21c14.58-8.38,25.57-24.17,26.54-41.17a3.25,3.25,0,0,1,.38-1.66c1.19-1.78,5.06,3.64,6.22,4.43,11.38,7.71,29.29-4,34-14.74-6.87,10-13.9,19.81-20.62,29.88-3.28,4.91-6.52,9.85-9.51,15-2.13,3.62-5.55,8.42-5.7,12.76a1.58,1.58,0,0,0,.2,1c.54.75,1.71.2,2.41-.4,12.1-10.49,21.2-24.45,32.69-35.65,5.63-5.49,11.43-11,18.21-15a37.18,37.18,0,0,1,11.1-4.63c1.75-.38,5.42-1.33,7.07-.5,3.09,1.56.1,3.24-1.79,5.21-2.17,2.26-28.8,25.8-20.09,28.15a4.38,4.38,0,0,0,2.34-.21c13.68-3.86,24.45-12,35.88-20l-15.35,18.64c1.05-1.28,12.66-1.25,14.9-1.74a82.35,82.35,0,0,0,15-4.8c8.81-3.76,17.92-9.07,24.53-16.09a16.67,16.67,0,0,0,3.78-5.63,7.62,7.62,0,0,0-.48-6.58c-3.18-5-10-3.32-14.42-1.42-10.42,4.48-19.5,11.67-27.33,19.78s-16.2,17.7-20.22,28.17c-3.5,9.12,6.91,13.47,14.24,11.58,10.51-2.7,19.87-8.88,28.45-15.32a372.61,372.61,0,0,0,28.83-23.75"
        ></path>
      </svg>
    </div>
  );
};

export default Write;
