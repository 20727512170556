import React from "react";

import Left from "../Side/Left";
import Right from "../Side/Right";

import "./Section6.css";

const Section6 = (props) => {
  return (
    <section id="Section6">
      <Left />
      <Right />

      <header className="category">
        <div>
          <img
            src={
              props.size <= 768 ? "../images/title/category_m.png" : "../images/title/category.png"
            }
            alt=""
          />
        </div>
      </header>

      <div className="contents YdestreetL">
        <div>
          <a className="fromLeft" href="#firstPage">
            About
          </a>
        </div>

        <div>
          <a href="#secondPage" className="fromLeft">
            Business
          </a>
        </div>

        <div>
          <a href="#thirdPage" className="fromLeft">
            Clients
          </a>
        </div>

        <div>
          <a href="#fourthPage" className="fromLeft">
            Partners
          </a>
        </div>

        <div>
          <a href="#fivePage" className="fromLeft">
            Contact
          </a>
        </div>
      </div>

      <div className="mobile">
        <a href="#firstPage" className="top" />
        <div className="copyright YdestreetL">Copyright ⓒ 2021 MMDCORE All Right Reserved.</div>
      </div>

      <div className="name YdestreetL">
        <div>MMD CORE</div>
      </div>

      <div className="sns">
        <div>
          <img
            data-sns="facebook"
            onMouseEnter={props.changeSns}
            onMouseLeave={props.changeSns}
            src={
              props.active.facebook
                ? "../images/section1/facebook.png"
                : "../images/section1/facebook.svg"
            }
            alt=""
          />
        </div>

        <div>
          <img
            data-sns="insta"
            onMouseEnter={props.changeSns}
            onMouseLeave={props.changeSns}
            src={
              props.active.insta
                ? "../images/section1/instagram.png"
                : "../images/section1/instagram.svg"
            }
            alt=""
          />
        </div>

        <div>
          <img
            data-sns="blog"
            onMouseEnter={props.changeSns}
            onMouseLeave={props.changeSns}
            src={props.active.blog ? "../images/section1/blog.png" : "../images/section1/blog.svg"}
            alt=""
          />
        </div>
      </div>
    </section>
  );
};

export default Section6;
