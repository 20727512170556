import React, { useRef, useState, useReducer, useEffect } from "react";
import ReactFullpage from "@fullpage/react-fullpage";

import Header from "../Header";
import Footer from "../Footer";
import Section1 from "../Section1";
import Section2 from "../Section2";
import Section3 from "../Section3";
import Section4 from "../Section4";
import Section5 from "../Section5";
import Section6 from "../Section6";

import "./Main.css";

const Main = () => {
  const videos = useRef([]);
  const [page, setPage] = useState(0);

  let afterLoad = (origin, index) => {
    if (index.index === 0) {
      videos.current[0].play();
    }

    if (index.index === 1) {
      videos.current[1].play();
    }

    if (index.index === 2) {
      videos.current[2].play();
    }

    setPage(index.index);
  };

  const [active, setActive] = useReducer((state, newState) => ({ ...state, ...newState }), {
    facebook: false,
    insta: false,
    blog: false,
  });

  const changeSns = (e) => {
    const sns = e.currentTarget.dataset.sns;

    setActive({ [sns]: !active[sns] });
  };

  const [size, setSize] = useState(window.innerWidth);

  useEffect(() => {
    function updateSize() {
      if (size <= 768) {
        setSize(window.innerWidth);
      } else {
        setSize(window.innerWidth);
      }
    }

    window.addEventListener("resize", updateSize);

    return () => window.removeEventListener("resize", updateSize);
  }, [size]);

  return (
    <div id="Main">
      <Header page={page} />

      <div className="name YdestreetL">
        <div>MMD CORE</div>
      </div>

      <div className="sns">
        <div>
          <img
            data-sns="facebook"
            onMouseEnter={changeSns}
            onMouseLeave={changeSns}
            src={
              active.facebook
                ? "../images/section1/facebook.png"
                : "../images/section1/facebook.svg"
            }
            alt=""
          />
        </div>

        <div>
          <img
            data-sns="insta"
            onMouseEnter={changeSns}
            onMouseLeave={changeSns}
            src={
              active.insta ? "../images/section1/instagram.png" : "../images/section1/instagram.svg"
            }
            alt=""
          />
        </div>

        <div>
          <img
            data-sns="blog"
            onMouseEnter={changeSns}
            onMouseLeave={changeSns}
            src={active.blog ? "../images/section1/blog.png" : "../images/section1/blog.svg"}
            alt=""
          />
        </div>
      </div>

      <ReactFullpage
        licenseKey="A7FD9930-22274D99-B399D5DE-E1B70C47"
        scrollingSpeed={1000}
        anchors={[
          "firstPage",
          "secondPage",
          "thirdPage",
          "fourthPage",
          "fivePage",
          "lastPage",
          "footerPage",
        ]}
        afterLoad={afterLoad}
        render={() => {
          return (
            <ReactFullpage.Wrapper>
              <div data-anchor="firstPage" className="section">
                <video
                  className="video1"
                  ref={(el) => (videos.current[0] = el)}
                  muted="muted"
                  playsInline={true}
                  src="https://mmdcore.s3.ap-northeast-2.amazonaws.com/videos/movie.mp4"
                  loop
                />

                <Section1 active={active} changeSns={changeSns} size={size} />
              </div>

              <div data-anchor="secondPage" className="section">
                <video
                  className="video2"
                  ref={(el) => (videos.current[1] = el)}
                  muted="muted"
                  playsInline={true}
                  src="https://mmdcore.s3.ap-northeast-2.amazonaws.com/videos/movie2.mp4"
                  loop
                />

                <Section2 size={size} />
              </div>

              <div data-anchor="thirdPage" className="section">
                <video
                  className="video3"
                  ref={(el) => (videos.current[2] = el)}
                  muted="muted"
                  playsInline={true}
                  src="https://mmdcore.s3.ap-northeast-2.amazonaws.com/videos/movie3.mp4"
                  loop
                />

                <Section3 size={size} />
              </div>

              <div data-anchor="fourthPage" className="section">
                <Section4 size={size} />
              </div>

              <div data-anchor="fivePage" className="section">
                <Section5 size={size} />
              </div>

              <div data-anchor="lastPage" className="section">
                <Section6 active={active} changeSns={changeSns} size={size} />
              </div>

              {size <= 768 ? (
                ""
              ) : (
                <div data-anchor="footerPage" id="footer" className="section fp-auto-height ">
                  <Footer />
                </div>
              )}
            </ReactFullpage.Wrapper>
          );
        }}
      />
    </div>
  );
};

export default Main;
