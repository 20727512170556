import React from "react";
import Write from "../Write";
import Left from "../Side/Left";
import Right from "../Side/Right";
import Fade from "react-reveal/Fade";

import "./Section3.css";

const Section3 = (props) => {
  return (
    <section id="Section3">
      <Left />
      <Right />
      <div className="gray" />

      <div className="info YdestreetL">
        <Fade bottom delay={500}>
          <header className="title">
            <img
              src={
                props.size <= 768
                  ? "../images/title/business_m.png"
                  : "../images/title/business.png"
              }
              alt=""
            />
          </header>

          <article className="sub">
            We are change tomorrow to strategies
            <br />
            and creativity that
            <br />
            meet the ear of the fourth industry
          </article>
        </Fade>

        <Fade bottom delay={1000}>
          <Write />
        </Fade>
      </div>
    </section>
  );
};

export default Section3;
