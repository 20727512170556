import React from "react";
import Write from "../Write";
import Left from "../Side/Left";
import Right from "../Side/Right";
import Fade from "react-reveal/Fade";

import "./Section2.css";

const Section2 = (props) => {
  return (
    <section id="Section2">
      <Left />
      <Right />
      <div className="gray" />

      <div className="info YdestreetL">
        <Fade bottom delay={500}>
          <header className="title">
            <img
              src={props.size <= 768 ? "../images/title/core_m.png" : "../images/title/core.png"}
              alt=""
            />
          </header>

          <article className="sub">
            We aim for innovation and change
            <br />
            for a sustainavle future<span className="italic">!</span>
          </article>
        </Fade>

        <Fade bottom delay={1000}>
          <Write />
        </Fade>
      </div>
    </section>
  );
};

export default Section2;
