import React from "react";
import Write from "../Write";
import Left from "../Side/Left";
import Right from "../Side/Right";
import Fade from "react-reveal/Fade";

import "./Section1.css";

const Section1 = (props) => {
  return (
    <section id="Section1">
      <Left />
      <Right />

      <div className="gray" />

      <div className="name YdestreetL">
        <div>MMD CORE</div>
      </div>

      <div>
        <div className="info YdestreetL">
          <Fade bottom delay={500}>
            <header className="title">
              <img
                src={
                  props.size <= 768
                    ? "../images/title/identity_m.png"
                    : "../images/title/identity.png"
                }
                alt=""
              />
            </header>

            <article className="sub">
              We specialize in research
              <br />
              and development
              <br />
              in the field of blockchain technology
            </article>

            <Write />
          </Fade>
        </div>
      </div>

      <div className="sns">
        <div>
          <img
            data-sns="facebook"
            onMouseEnter={props.changeSns}
            onMouseLeave={props.changeSns}
            src={
              props.active.facebook
                ? "../images/section1/facebook.png"
                : "../images/section1/facebook.svg"
            }
            alt=""
          />
        </div>

        <div>
          <img
            data-sns="insta"
            onMouseEnter={props.changeSns}
            onMouseLeave={props.changeSns}
            src={
              props.active.insta
                ? "../images/section1/instagram.png"
                : "../images/section1/instagram.svg"
            }
            alt=""
          />
        </div>

        <div>
          <img
            data-sns="blog"
            onMouseEnter={props.changeSns}
            onMouseLeave={props.changeSns}
            src={props.active.blog ? "../images/section1/blog.png" : "../images/section1/blog.svg"}
            alt=""
          />
        </div>
      </div>
    </section>
  );
};

export default Section1;
