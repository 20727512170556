import React, { useEffect, useState, useReducer } from "react";
import Left from "../Side/Left";
import Right from "../Side/Right";

import "./Header.css";

const Header = (props) => {
  const [toggle, setToggle] = useState(false);

  const toggleState = () => {
    setToggle(!toggle);
  };

  useEffect(() => {
    setToggle(false);
  }, [props]);

  const [active, setActive] = useReducer((state, newState) => ({ ...state, ...newState }), {
    facebook: false,
    insta: false,
    blog: false,
  });

  const changeSns = (e) => {
    const sns = e.currentTarget.dataset.sns;

    setActive({ [sns]: !active[sns] });
  };

  return (
    <div id="Header">
      <Left />
      <Right />

      <header className="header">
        <div className="logo">
          <a className="font_white" href="#firstPage" onClick={toggleState}>
            <img src="../images/header/logo.svg" alt="" />
          </a>
        </div>

        <div id="menuToggle" className={`burger ${toggle ? "" : "border"}`}>
          <input type="checkbox" onChange={toggleState} checked={toggle} />

          <span className={toggle ? "size_up" : ""}></span>
          <span></span>
          <span className={toggle ? "size_up" : ""}></span>
        </div>

        <div className={toggle ? "side-menu-active" : "side-menu"}>
          <div className="side-menu-wrap YdestreetL">
            <ul>
              <li>
                <a className="font_white" href="#firstPage" onClick={toggleState}>
                  About
                </a>
              </li>

              <li>
                <a className="font_green" href="#secondPage" onClick={toggleState}>
                  Business
                </a>
              </li>

              <li>
                <a className="font_green" href="#thirdPage" onClick={toggleState}>
                  Clients
                </a>
              </li>

              <li>
                <a className="font_green" href="#fourthPage" onClick={toggleState}>
                  Partners
                </a>
              </li>

              <li>
                <a className="font_purple" href="#fivePage" onClick={toggleState}>
                  Contact
                </a>
              </li>
            </ul>

            <div className="sns">
              <div>
                <img
                  data-sns="facebook"
                  onMouseEnter={changeSns}
                  onMouseLeave={changeSns}
                  src={
                    active.facebook
                      ? "../images/section1/facebook.png"
                      : "../images/section1/facebook.svg"
                  }
                  alt=""
                />
              </div>

              <div>
                <img
                  data-sns="insta"
                  onMouseEnter={changeSns}
                  onMouseLeave={changeSns}
                  src={
                    active.insta
                      ? "../images/section1/instagram.png"
                      : "../images/section1/instagram.svg"
                  }
                  alt=""
                />
              </div>

              <div>
                <img
                  data-sns="blog"
                  onMouseEnter={changeSns}
                  onMouseLeave={changeSns}
                  src={active.blog ? "../images/section1/blog.png" : "../images/section1/blog.svg"}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
