import React from "react";

// CSS
import "./Left.css";

let Left = () => {
  return (
    <div id="Left">
    </div>
  );
};

export default Left;