import React from "react";
import { Route } from "react-router-dom";

import MainContainer from "./containers/MainContainer";

import "./App.css";

const App = () => {
  return (
    <div id="App">
      <Route exact path="/" component={MainContainer} />
    </div>
  );
};

export default App;
