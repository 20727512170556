import React from "react";

// CSS
import "./Right.css";

let Right = () => {
  return (
    <div id="Right">
    </div>
  );
};

export default Right;