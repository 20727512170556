import React, { useEffect, useReducer } from "react";
import Left from "../Side/Left";
import Right from "../Side/Right";
import Fade from "react-reveal/Fade";

import "./Section4.css";

const Section4 = (props) => {
  const [active, setActive] = useReducer((state, newState) => ({ ...state, ...newState }), {
    one: false,
    two: false,
    three: false,
  });

  const changeBackground = (e) => {
    const field = e.currentTarget.dataset.field;
    const sectionImg = document.querySelector("#Section4");
    setActive({ one: false });

    switch (field) {
      case "one":
        sectionImg.style.backgroundImage = "url('/images/section4/background1.jpg')";
        break;
      case "two":
        sectionImg.style.backgroundImage = "url('/images/section4/background2.jpg')";
        break;

      case "three":
        sectionImg.style.backgroundImage = "url('/images/section4/background3.jpg')";
        break;

      default:
        break;
    }

    setActive({ [field]: !active[field] });
  };

  useEffect(() => {
    if (props.size <= 1254) {
      setActive({ one: true });
    } else {
      setActive({ one: false });
    }
  }, [props.size]);

  return (
    <section id="Section4">
      <Left />
      <Right />
      <div className="gray" />

      <div className="wrap YdestreetL">
        <Fade bottom delay={300}>
          <div
            onMouseEnter={changeBackground}
            onMouseLeave={changeBackground}
            data-field="one"
            className={active.one ? "hover" : ""}
          >
            <header className="title">
              STATEGIC
              <br />
              SOLUTION
            </header>

            <article className="info">
              브랜드를 전문적으로 진단하고 전략 솔루션을 제공합니다.
            </article>

            <div className={`view ${active.one ? "backImg" : ""}`}></div>
          </div>
        </Fade>

        <Fade bottom delay={600}>
          <div
            data-field="two"
            onMouseEnter={changeBackground}
            onMouseLeave={changeBackground}
            className={active.two ? "hover" : ""}
          >
            <header className="title">I.M.C.</header>

            <article className="info">
              데이터를 기반으로 소비자 인사이트를 도출하고, 온라인, 모바일, 소셜, 디지털 등 다양한
              미디어를 아우르는 회적의 크리에이티브와 솔루션을 제공합니다.
            </article>

            <div className={`view ${active.two ? "backImg" : ""}`}></div>
          </div>
        </Fade>

        <Fade bottom delay={900}>
          <div
            data-field="three"
            onMouseEnter={changeBackground}
            onMouseLeave={changeBackground}
            className={active.three ? "hover" : ""}
          >
            <header className="title">DEVELOPMENT</header>

            <article className="info">
              최고의 전문가 집단이 고객 비즈니스에 맞춘 커스터마이징 개발을 진행합니다.
            </article>

            <div className={`view ${active.three ? "backImg" : ""}`}></div>
          </div>
        </Fade>
      </div>
    </section>
  );
};

export default Section4;
