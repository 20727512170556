import React from "react";
import Left from "../Side/Left";
import Right from "../Side/Right";

// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

// import ImageGallery modules
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

import "./Section5.css";

SwiperCore.use([Pagination, Navigation]);

const Section5 = (props) => {
  const images = [
    {
      original: "/images/section5/handshake.jpg",
      thumbnail: "/images/section5/handshake.jpg",
      renderItem: renderText,
      title: "MMDCORE, US 노스랜드와 비즈니스 사업 MOU체결",
    },
    {
      original: "/images/section5/bulid.jpg",
      thumbnail: "/images/section5/bulid.jpg",
      renderItem: renderText,
      title: "MMD CORE, 블록체인 연합회 산업육성 협약",
    },
    {
      original: "/images/section5/handshake.jpg",
      thumbnail: "/images/section5/handshake.jpg",
      renderItem: renderText,
      title: "MMDCORE, US 노스랜드와 비즈니스 사업 MOU체결",
    },
    {
      original: "/images/section5/bulid.jpg",
      thumbnail: "/images/section5/bulid.jpg",
      renderItem: renderText,
      title: "MMD CORE, 블록체인 연합회 산업육성 협약",
    },
  ];

  function renderText(item) {
    return (
      <div className="image_wrap">
        <img className="image-gallery-image" src={item.original} title="" alt="" />
        <span className="image-gallery-description YdestreetL">{item.title}</span>
        <div className="mobile_date YdestreetL">Nov 15, 2021</div>
      </div>
    );
  }
  return (
    <section id="Section5">
      <Left />
      <Right />

      <header className="title">
        <div>
          <img
            src={props.size <= 768 ? "../images/title/press_m.png" : "../images/title/press.png"}
            alt=""
          />
        </div>
      </header>

      <Swiper
        slidesPerView={"auto"}
        centeredSlides={true}
        speed={800}
        spaceBetween={80}
        navigation={true}
        className="web"
        loop={true}
      >
        <SwiperSlide>
          <div className="gradient"></div>

          <div className="contents">
            <div className="date YdestreetL"> Nov 15, 2021</div>

            <div>
              <img src="/images/section5/handshake.jpg" alt="" />
            </div>

            <div className="info YdestreetL">MMDCORE, US 노스랜드와 비즈니스 사업 MOU체결</div>
          </div>

          <div className="contents">
            <div className="date YdestreetL"> Nov 15, 2021</div>

            <div>
              <img src="/images/section5/bulid.jpg" alt="" />
            </div>

            <div className="info YdestreetL">MMD CORE, 블록체인 연합회 산업육성 협약</div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="gradient"></div>

          <div className="contents">
            <div className="date YdestreetL"> Nov 15, 2021</div>

            <div>
              <img src="/images/section5/handshake.jpg" alt="" />
            </div>

            <div className="info YdestreetL">MMDCORE, US 노스랜드와 비즈니스 사업 MOU체결</div>
          </div>

          <div className="contents">
            <div className="date YdestreetL"> Nov 15, 2021</div>

            <div>
              <img src="/images/section5/bulid.jpg" alt="" />
            </div>

            <div className="info YdestreetL">MMD CORE, 블록체인 연합회 산업육성 협약</div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="gradient"></div>

          <div className="contents">
            <div className="date YdestreetL"> Nov 15, 2021</div>

            <div>
              <img src="/images/section5/handshake.jpg" alt="" />
            </div>

            <div className="info YdestreetL">MMDCORE, US 노스랜드와 비즈니스 사업 MOU체결</div>
          </div>

          <div className="contents">
            <div className="date YdestreetL"> Nov 15, 2021</div>

            <div>
              <img src="/images/section5/bulid.jpg" alt="" />
            </div>

            <div className="info YdestreetL">MMD CORE, 블록체인 연합회 산업육성 협약</div>
          </div>
        </SwiperSlide>
      </Swiper>

      <ImageGallery
        items={images}
        showPlayButton={false}
        autoPlay={true}
        showNav={false}
        showFullscreenButton={false}
      />
    </section>
  );
};

export default Section5;
