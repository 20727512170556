import React from "react";

import Left from "../Side/Left";
import Right from "../Side/Right";

import "./Footer.css";

const Footer = () => {
  return (
    <footer id="Footer" className="YdestreetL">
      <Left />
      <Right />
      Copyright ⓒ 2021 MMDCORE All Right Reserved.
    </footer>
  );
};

export default Footer;
